@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap");

html {
  background: var(--background);
}

img {
  display: block;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  font-variant-ligatures: none;
  padding: 0px;
  margin: 0px;
  font-family: "Barlow", sans-serif;
  box-sizing: border-box;
  word-wrap: break-word;
  color: var(--text);
  scrollbar-color: var(--accent) var(--background);
}

:focus {
  outline: none;
}

:root {
  /* --background: rgb(15, 15, 15); */
  /* --background2: rgb(25, 25, 25); */
  /* --text: #fff; */
  --accent: rgb(150, 0, 50);
  /* --accent2: rgb(255, 0, 255); */
  --grey: #ddd;
  /* --disabled: rgb(255, 150, 255); */
  --page-width: 800px;
  --content-width: 500px;
  --button-width: 400px;
  --box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  --radius: 1.5rem;
  background: var(--background);
  transition: color 0.3s;
  transition: background-color 0.3s;
}

body {
  margin: 0;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background-color: #c70039;
}

h1 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

h3 {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

h4 {
  color: var(--accent);
  margin-bottom: 2rem;
  font-weight: 400;
}

p {
  margin-bottom: 2rem;
}

a {
  color: unset;
  text-decoration: none;
}

a:hover {
  color: rgb(150, 0, 50);
}

ol {
  margin-bottom: 2rem;
}

li {
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.flex.wrap {
  flex-wrap: wrap;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.5rem;
}

.block {
  background: var(--background2);
  border-radius: var(--radius);
  box-shadow: var(--box-shadow);
  padding: 2rem;
}

.block > :last-child {
  margin-bottom: unset;
}

.hover {
  cursor: pointer;
  user-select: none;
}

.hover:hover {
  color: var(--accent);
}

button {
  background: rgb(150, 0, 50);
  color: white;
  font-weight: 700;
  border-radius: 2rem;
  line-height: 1rem;
  padding: 1rem 2rem;
  box-shadow: var(--box-shadow);
  text-align: center;
  width: 100%;
  max-width: var(--button-width);
  margin: auto;
  margin-bottom: 2rem;
  user-select: none;
  cursor: pointer;
  display: block;
  border: none;
}

button:hover,
button:focus {
  outline: 0;
  background: rgb(200, 50, 100);
}

.flex > button,
.vertical-flex > button {
  margin-bottom: 0;
}

.page-wrapper {
  padding: 3rem 0;
  flex-grow: 1;
  padding-top: 12rem;
}

@media (max-width: 700px) {
  .page-container {
    padding: 0 1rem !important;
  }
}

.page-container {
  padding: 0 2rem;
  box-sizing: content-box;
  margin: auto;
  max-width: var(--page-width);
}

.clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.icon {
  background: white;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  --fb: #1877f2;
  --ig: #c13584;
  --tw: #1da1f2;
  --yt: #ff0000;
  --sp: #1db954;
  --sc: #ff7700;
}

:hover > .icon.hover,
.hover:hover > .icon,
:focus > .icon.hover,
.hover:focus > .icon {
  cursor: pointer;
  background: var(--accent);
}

.icon._tiktok {
  mask-image: url("./utils/tiktok.png");
}

.icon._youtube {
  width: 2.6rem;
  height: 2.6rem;
  background: var(--yt);
  mask-image: url("./utils/youtube.png");
}

.icon._instagram {
  background: var(--ig);
  mask-image: url("./utils/instagram.png");
}

.icon._spotify {
  background: var(--sp);
  mask-image: url("./utils/spotify.png");
}

.center {
  text-align: center;
  justify-content: center;
}
