.press-quote {
  margin-bottom: 5rem;
  background: white;
  padding: 2rem;
  border-radius: 1rem;

  
}

.press-quote q {
  margin-bottom: 2rem;
  display: block;

}

.press-quote a {
  text-decoration: underline;
}

.press-quote h2 {
  margin-bottom: 1rem;
}

.press-quote._preview {
  margin-bottom: 2rem;
}
