.menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
}

.menu-option {
  color: white;
  padding: 1rem 0;
  font-size: 2rem;
  font-weight: 700;
  display: block;
  width: max-content;
}
