.header-wrapper {
  background: rgba(0, 0, 0, 0.6);
  padding: 1rem 0;
  user-select: none;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
}

.header img {
  width: 5rem;
  max-width: 100%;
}

.header-box {
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
}

.header-name {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
}

.link-icon {
  height: 40px;
  width: 40px;
}

.menu-icon {
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  mask-image: url("../../utils/menu.png");
  mask-size: 100%;
  mask-repeat: no-repeat;
  mask-position: center;
  background: white;
  cursor: pointer;
}

.menu-icon:hover {
  background: grey;
}
