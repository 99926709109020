.page-wrapper._home {
  padding-top: unset;
  scroll-behavior: smooth; /* enables smooth scrolling */
}

.home-section {
  margin-bottom: 10rem;
}

.home-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.splash.flex {
  margin-bottom: 5rem;
  height: 100vh;
  padding-bottom: 7rem;
  align-items: flex-end;
}

.splash-text {
  background: rgba(255, 255, 255, 0.8);
  color: black;
  border-radius: 1rem;
  padding: 3rem;
}

.splash-text h3 {
  color: black;
}

.splash button {
  margin-bottom: unset;
}

.splash-pic {
  height: 100vh;
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
}

.scroll-down-arrow-1 {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  width: 30px;
  height: 30px;
  border-left: 3px solid white;
  border-bottom: 3px solid white;
  animation: bounce 2s infinite;
  opacity: 0.7;
  z-index: 2;
}

.scroll-down-arrow-2 {
  position: absolute;
  bottom: -42rem;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  width: 30px;
  height: 30px;
  border-left: 3px solid white;
  border-bottom: 3px solid white;
  animation: bounce 2s infinite;
  opacity: 0.7;
  z-index: 2;
}

.scroll-down-arrow-3 {
  position: absolute;
  bottom: -82rem;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  width: 30px;
  height: 30px;
  border-left: 3px solid white;
  border-bottom: 3px solid white;
  animation: bounce 2s infinite;
  opacity: 0.7;
  z-index: 2;
}

.scroll-down-arrow-4 {
  position: absolute;
  bottom: -127rem;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  width: 30px;
  height: 30px;
  border-left: 3px solid white;
  border-bottom: 3px solid white;
  animation: bounce 2s infinite;
  opacity: 0.7;
  z-index: 2;
}



@keyframes bounce {
  0%, 100% {
    transform: translateY(0) rotate(-45deg);
  }
  50% {
    transform: translateY(10px) rotate(-45deg);
  }
}

.tiktok {
  margin-bottom: 2rem;
}

iframe {
  border: unset;
  background: unset;
  border-radius: 1rem;
}

.yt-vid {
  padding-top: 56.25%;
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
}

.yt-vid iframe {
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  border-radius: 1rem;
}

.spotify-playlist {
  border-radius: 1rem;
}

.text-us {
  font-size: 1.2rem;
  background: rgba(0, 0, 0, 0.8);
  padding: 2rem;
  border-radius: 1rem;
  color: white;
  font-weight: 700;
  text-align: center;
}
