.page-wrapper._tour header {
  text-align: center;
  margin-bottom: 5rem;
}

.page-wrapper._tour header h1 {
  margin-bottom: 2rem;
}

.tour-subtitle {
  font-size: 1.4rem;
  color: var(--accent);
}

.tour-date {
  text-align: center;
  margin-bottom: 5rem;
  padding-bottom: 5rem;
}

.tour-date:not(:last-child) {
  border-bottom: 5px dashed var(--accent);
}

.tour-date button {
  margin-bottom: unset;
}

.tour-date:nth-child(2n+1) {
  margin-left: auto;
}