.footer-wrapper {
  background: rgba(0, 0, 0, 0.6);
  padding: 2rem 0;
  user-select: none;
  position: sticky;
  top: 0;
  z-index: 5;
}

.footer img {
  width: 5rem;
  max-width: 100%;
}

.footer-box {
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
}

.footer-name {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
}

.footer-box a {
  margin: 0 1rem;
}
