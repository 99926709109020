.page-wrapper.about {
  overflow: hidden;
  position: relative;
  /* margin-bottom removed to bring sections closer */
}

.about-page .pic {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
}

.bio {
  font-size: 1.2rem;
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.scroll-down-arrow-5 {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  width: 30px;
  height: 30px;
  border-left: 3px solid white;
  border-bottom: 3px solid white;
  animation: bounce 2s infinite;
  opacity: 0.7;
  z-index: 2;
}

.member-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem; /* even spacing between members */
}

.band-member {
  position: relative;
  text-align: center;
  width: 250px;
  margin-bottom: 2rem;
}

.headshot {
  display: block;
  height: 15rem;
  width: 15rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  transition: filter 0.3s ease;
  text-decoration: none;
  cursor: pointer;
}

.headshot:hover {
  filter: brightness(60%);
}

.role-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 1rem;
  pointer-events: none;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
}

.band-member:hover .role-overlay {
  opacity: 1;
}

/* === Headshot background images with tweaks === */
.headshot._adam {
  background-image: url("../utils/ADAM.jpg");
  background-size: 160%;
  background-position: center 35%;
}

.headshot._ashley {
  background-image: url("../utils/ASHLEY.jpg");
  background-position: center;
}

.headshot._evan {
  background-image: url("../utils/EVAN.jpg");
  background-size: 140%;
  background-position: center 20%;
  background-position: left 40%;
}

.headshot._chris {
  background-image: url("../utils/CHRIS.jpg");
  background-position: center 0% ;
  Background-size: 120%
 

}

.headshot._alec {
  background-image: url("../utils/ALEC.jpg");
  background-position: center 60%;
}

.headshot._coby {
  background-image: url("../utils/COBY.jpg");
}

.headshot._jack {
  background-image: url("../utils/JACK.jpg");
}

.headshot._henry {
  background-image: url("../utils/HENRY.jpg");
}

