.show-poster {
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.show-poster._past {
  width: 390px;
}

@media (max-width: 700px) {
  .show-poster._past {
    width: 180px !important;
  }
}

.past-shows-grid.flex {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
